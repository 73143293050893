<template>
  <FullPage>
    <h2
      slot="title"
      class="login-box-title"
    >
      {{ $t('loginView.title') }}
      <span class="login-box-title--blue"> {{ $t('loginView.titleBlue') }}</span>
    </h2>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <el-form
        ref="form"
        label-position="top"
        :model="formObject"
        class="login-form"
      >
        <FormItem
          rules="required|email"
          :label="$t('loginView.usernameInput')"
        >
          <el-input
            v-model="formObject.email"
          />
        </FormItem>
        <FormItem
          rules="required"
          :label="$t('loginView.passwordInput')"
        >
          <el-input
            v-model="formObject.password"
            show-password
          />
        </FormItem>
        <el-row
          type="flex"
          justify="space-between"
        >
          <el-col :span="18">
            <router-link to="password-recover">
              {{ $t('loginView.passwordForgotLabel') }}
            </router-link>
          </el-col>
          <el-col
            :span="6"
            class="submit-container"
          >
            <FormItem margin-bottom-size="none">
              <el-button
                type="primary"
                native-type="submit"
                :disabled="invalid"
                @click.prevent="handleSubmit(access)"
              >
                {{ $t('loginView.accessButton') }}
              </el-button>
            </FormItem>
          </el-col>
        </el-row>
      </el-form>
    </ValidationObserver>
  </FullPage>
</template>

<script>

import { mapActions } from 'vuex';
import FormItem from '@/components/FormItem.vue';
import FullPage from '@/components/FullPage.vue';
import users from '../api/users';


export default {
  name: 'Login',
  components: {
    FormItem,
    FullPage,
  },
  data() {
    return {
      formObject: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
  },
  methods: {
    ...mapActions([
      'setLoggedUser',
    ]),
    async access() {
      try {
        await users.login(this.formObject);
        this.$router.push({ name: 'postLogin' });
      } catch (error) {
        this.$notify({ type: 'error', message: error.response.data.error.message });
      }
    },
  },
};
</script>

<style lang="scss">
.login-box-title {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  line-height: 1;
  margin-bottom: 40px;

  &--blue {
    color: $--color-primary;
  }
}
.login-form {
  .submit-container {
    text-align: right;
  }
}
</style>
